import { ChainId } from '@abstra-dex/sdk'
import { serializeTokens } from './tokens'
import { SerializedFarmConfig } from './types'
import { CHAIN_ID } from './networks'

const serializedTokens = serializeTokens()

const chainId = parseInt(CHAIN_ID, 10)
const { MAINNET } = ChainId

export const VLX_WAG_FARM_PID = chainId === MAINNET ? 1 : 1 // WAG-VLX (2)
export const VLX_USDT_FARM_PID = chainId === MAINNET ? 1 : 1 // BUSD-VLX (3)

const farms: SerializedFarmConfig[] =
  chainId === MAINNET
    ? [
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'AbstraDEX',
          lpAddresses: {
            33139: '0xa8d9b7a299248fa20f1c88ca385ba1908cf31ee8',
            195: '',
          },
          token: serializedTokens.syrup,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,
          lpSymbol: 'ABS-OKB LP',
          lpAddresses: {
            33139: '0xedc42d2f408b4dc8fa5ac0a6f92abd6235a3e138',
            195: '',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 2,
          v1pid: 2,
          lpSymbol: 'OKB-USDT LP',
          lpAddresses: {
            33139: '0x721e5d8f4eeebd5c2f2a7178e5ddae599654b95c',
            195: '',
          },
          token: serializedTokens.usdt,
          quoteToken: serializedTokens.weth,
        },
      ]
    : [
        /**
         * These 3 farms (PID 0, 251, 252) should always be at the top of the file.
         */
        {
          pid: 0,
          v1pid: 0,
          lpSymbol: 'ABS',
          lpAddresses: {
            33139: '',
            195: '0x398F92e15519B4F6F2D5cc42C17FC9ED15De53d8',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        {
          pid: 1,
          v1pid: 1,
          lpSymbol: 'ABS-ZETA LP',
          lpAddresses: {
            33139: '',
            195: '0xfC8c6FA1d473444d160bab7146bDecc8bf1d24Eb',
          },
          token: serializedTokens.cake,
          quoteToken: serializedTokens.weth,
        },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'QS_ETH LP',
        //   lpAddresses: {
        //     33139: '0xdD58B168d467EDe6AD89620355198B2B81305041',
        //     195: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.weth,
        // },
        // {
        //   pid: 2,
        //   v1pid: 2,
        //   lpSymbol: 'VLX_TE9 LP',
        //   lpAddresses: {
        //     33139: '0x40d4fC460eF4C76a20CE1BaEAEB6b921050f483f',
        //     195: '',
        //   },
        //   token: serializedTokens.te9,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 3,
        //   v1pid: 3,
        //   lpSymbol: 'VLX_TE12 LP',
        //   lpAddresses: {
        //     33139: '0xc40969848d5B549138f0b1B499a69fA9B510407d',
        //     195: '',
        //   },
        //   token: serializedTokens.te12,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 4,
        //   v1pid: 4,
        //   lpSymbol: 'VLX_TE18 LP',
        //   lpAddresses: {
        //     33139: '0xf01D3d7827211626E7b76DD583EaC8b369d046b0',
        //     195: '',
        //   },
        //   token: serializedTokens.te18,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 5,
        //   v1pid: 5,
        //   lpSymbol: 'VLX_ETH LP',
        //   lpAddresses: {
        //     33139: '0x8A70d2a3e2cba2CAD61FbA419E62eB821F18Bb57',
        //     195: '',
        //   },
        //   token: serializedTokens.weth,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 6,
        //   v1pid: 6,
        //   lpSymbol: 'VLX_USDT LP',
        //   lpAddresses: {
        //     33139: '0xF20c93c5e5F534C9D95567497Ea17a841164d37b',
        //     195: '',
        //   },
        //   token: serializedTokens.usdt,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 7,
        //   v1pid: 7,
        //   lpSymbol: 'VLX_USDC LP',
        //   lpAddresses: {
        //     33139: '0x33ea93e391aF9cAA4b8e9C3368236B93DFCF39C4',
        //     195: '',
        //   },
        //   token: serializedTokens.usdc,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 8,
        //   v1pid: 8,
        //   lpSymbol: 'VLX_BUSD LP',
        //   lpAddresses: {
        //     33139: '0xe25107384e3d23403c4537967D34cCe02A2b56c6',
        //     195: '',
        //   },
        //   token: serializedTokens.busd,
        //   quoteToken: serializedTokens.wvlx,
        // },
        // {
        //   pid: 9,
        //   v1pid: 9,
        //   lpSymbol: 'VLX_WAG LP',
        //   lpAddresses: {
        //     33139: '0xdC415f9c745a28893b0Cbb6A8eaC1bb6ed42C581',
        //     195: '',
        //   },
        //   token: serializedTokens.cake,
        //   quoteToken: serializedTokens.wvlx,
        // },
      ]

export default farms
